<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">{{ $t('menu.setting') }}</v-card-text>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tabIdx" align-tabs="center" bg-color="deep-purple-accent-4" stacked @change="tabClick">
        <v-tab> 공통 상품타입/아이템관리 </v-tab>
        <!-- <v-tab> doing Labs </v-tab>
        <v-tab> ~ </v-tab> -->
      </v-tabs>

      <v-tabs-items v-model="tabIdx">
        <ProductTypeItem ref="refProductTypeItem" />
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
import ProductTypeItem from "./productTypeItem/ProductTypeItem.vue";
export default {
  components: { ProductTypeItem },
  data() {
    return {
      tabIdx: 0,
    };
  },
  mounted() {
    this.tabClick(this.tabIdx);
  },
  methods: {
    tabClick(e) {
      console.log("e", e);
      this.tabIdx = e;
      setTimeout(() => {
        if (e == 0) this.$refs.refProductTypeItem._infoUpdate();
        // if (e == 1) this.$refs.refProductTypeItem._infoUpdate();
      }, 100);
    },
  },
};
</script>
<style lang="">
</style>