<template>
  <div>
    <div class="top">
      <div class="tableTitle">
        <SelectBoxHospitalType 
          selectStyleWidth="width: 200px"
          @changeSelect="hospitalType_changeSelect"/>
        <!-- {{ title }} --> 
      </div>
      <div class="btnPos">
        <v-btn v-if="hospitalType && hospitalType !== ''" color="primary" depressed small @click="clickType(null)">Add</v-btn>
      </div>
    </div>

    <TypeCard :data="productTypeData" @clickType="clickType" @clickItem="clickItem"/>
    <!-- <DataTable ref="DataTable" :loading="loading" :loadingText="loadingText" :tableKey="'rowSeq'" 
      :footerHide="footerHide" :singleSelect="true" :showSelect="false" :perPage="3000"
      :columns="tableColumns" :inputData="productTypeData" @clickRow="clickRow" /> -->

    <!-- type 수정 -->
    <DialogProductType ref="refEditModal" @saveComplete="typeSaveComplete" />

    <!-- item 수정 -->
    <DialogProductTypeItem ref="refItemEditModal" @saveComplete="itemSaveComplete" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import SelectBoxHospitalType from "@/components/commonV2/ui/SelectBoxHospitalType.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import TypeCard from "./comp/TypeCard.vue";
import DialogProductType from "@/components/commonV2/Dialog/DialogProductType.vue";
import DialogProductTypeItem from "@/components/commonV2/Dialog/DialogProductTypeItem.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    SelectBox,
    SelectBoxHospitalType,
    TypeCard,
    DataTable,
    DialogProductType,
    DialogProductTypeItem
  },
  props: {
    productTypeData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      title: "상품구분",
      loading: false,
      loadingText: "Loading list...",
      itemsHospitalType: formatService.hospitalTypeItem(),
      tableColumns: formatService.productTypeTableColumns(),
      footerHide: true,
      hospitalType: "",
    };
  },
  watch: {
    productTypeData(val) {
      if (val === null) return;
      // this.footerHide = val.length > 5 ? false : true;
    },
  },
  methods: {
    hospitalType_changeSelect(e) {
      this.hospitalType = e;
      this.$emit("hospitalTypeChange", e);
    },
    // openNewType() {
    //   this.$refs.refEditModal.showModal(this.title, this.productTypeData, null, "Y", this.hospitalType);
    // },
    clickType(item) {
      // console.log('item', item);
      console.log('this.productTypeData', this.productTypeData);
      this.$refs.refEditModal.showModal(this.title, this.productTypeData, item, "Y", this.hospitalType);
    },
    clickItem(typeId, item) {
      console.log('clickItem', typeId, item);
      // item.type_id = typeId;
      this.$refs.refItemEditModal.showModal(this.title, null, this.productTypeData, item, typeId);
    },
    typeSaveComplete() {
      this.$emit("saveComplete");
    },
    itemSaveComplete() {
      this.$emit("saveComplete");
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.top {
  display: flex;
  margin-bottom: 10px;
}

.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}
</style>