<template>
  <v-dialog v-model="visible" max-width="800px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> 상품 아이템관리 </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <SelectBox label="* 상품타입" 
              selectStyleWidth="width: 100%" 
              :value="selectedProductType" 
              :items="itemsProductType" 
              :multiple="false"
              @changeSelect="productType_changeSelect" />
          </v-row>

          <TitleField 
            label="아이템명" 
            :rules="[filedRules.required]"
            :titleData="targetModel.itemName" 
            @complete="targetModel.itemName = $event" />
          <!-- <v-row>
            <v-text-field label="아이템명" type="text" ref="refProductTypeItemName" v-model="targetModel.itemName" :rules="[filedRules.required]" />
          </v-row> -->
          <!-- <v-row>
            <RadioComps
              :items="methodTypeItems"
              :prosSelected="methodTypeValue"
              @changeType="methodTypeChangeType"
            />
          </v-row> -->
        </v-form>
      </v-card-text>

      <CommonBtn :propsKey="targetModel.item_id" @save="clickSave" @delete="deleteData" @cancel="clickCancel" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    TitleField,
    CommonBtn,
    SelectBox,
    RadioComps,
  },
  data() {
    return {
      visible: false,
      editType: "",
      selectedProductType: "",
      itemsProductType: [],
      methodTypeValue: "시술",
      methodTypeItems: formatService.methodTypeItems(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      filedRules: rulesService.filedRules(),
      targetModel: modelService.productTypeItemModel(),
    };
  },
  methods: {
    showModal(editType, clinicData, productTypeData, item, typeId) {
      this.visible = true;
      this.editType = editType;
      this.itemsProductType = productTypeData;
      this.selectedProductType = typeId;
      this.targetModel = item === null ? modelService.productTypeItemModel():item;
      this.targetModel.endpoint = "productTypeItem";
      this.targetModel.type_id = typeId;
      console.log('typeId', typeId);
      console.log('this.targetModel', this.targetModel);
    },
    close() {
      this.visible = false;
    },
    productType_changeSelect(e) {
      this.targetModel.type_id = e;
    },
    // methodTypeChangeType(e) {
    //   console.log("", e);
    //   this.targetModel.methodType = e;
    // },
    clickSave() {
      // 필수
      const $h = this.$helper;
      const D = this.targetModel;
      console.log('D', D);
      
      let msg = `상품타입${this.requiredMsg}`;
      if (!$h.requiredCheck(D.type_id, null, msg, false)) return;

      if (!this.$refs.form.validate()) return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
  computed: {
    ...mapGetters(["isDev", "meInfo"]),
  },
};
</script>