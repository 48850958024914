<template>
  <v-dialog v-model="visible" max-width="800px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> 
        {{ $helper.hospitalTypeLbl(targetModel.hospitalType) }} 
        >
        {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <TitleField 
            label="상품구분명" 
            :rules="[filedRules.required]"
            :titleData="targetModel.productTypeName" 
            @complete="targetModel.productTypeName = $event" />
          <!-- <v-row>
            <v-text-field
              label="상품구분명"
              type="text"
              ref="refProductTypeName"
              v-model="targetModel.productTypeName"
              :rules="[filedRules.required]"
            />
          </v-row> -->
        </v-form>
      </v-card-text>

      <CommonBtn
        :propsKey="targetModel.type_id"
        @save="clickSave"
        @delete="deleteData"
        @cancel="clickCancel"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    TitleField
  },
  data() {
    return {
      visible: false,
      editType: "",
      requiredMsg: requireService.requiredFieldAlertMessage(),
      filedRules: rulesService.filedRules(),
      targetModel: modelService.productTypeItemModel(),
      hospitalType: "",
    };
  },
  methods: {
    showModal(editType, patientData, item, isCommon, hospitalType) {
      this.visible = true;
      this.editType = editType;
      this.targetModel = item === null? modelService.productTypeItemModel():item;
      this.targetModel.hospitalType = hospitalType;
      this.targetModel.isCommon = isCommon === null ? 'N':isCommon;
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      if(isCommon === 'Y') this.targetModel.hospital_id = 0;
      this.targetModel.endpoint = "productType";
    },
    close() {
      this.visible = false;
    },
    updateSelectPrimaryPhysician(e) {
      this.targetModel.provider_id = e;
    },
    clickSave() {
      if (!this.$refs.form.validate()) return;
      // console.log('save', this.targetModel);
      // return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      // 서브아이템 갯수확인?
      if(this.targetModel.productTypeItem.length > 0) {
        let msg = "아이템이 존재합니다(삭제할수 없습니다)"
        this.$helper.showTostMessage("", msg, "error");
        return;
      }
      
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>