<template>
  <!-- <div>{{ clinicData }}상품정보 관리</div> -->
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <ProductType ref="refProductType" 
        :productTypeData="productTypeData" 
        @hospitalTypeChange="hospitalTypeChange" 
        @saveComplete="_infoUpdate" />

      <!-- <div class="vGap" /> -->

      <!-- <ProductTypeItem ref="refProductTypeItem" :clinicData="clinicData" :productTypeData="productTypeData" /> -->
    </v-card>
  </v-tab-item>
</template>
<script>
import { mapActions } from "vuex";
import ProductType from "./type/ProductType.vue";
import ProductTypeItem from "./item/ProductTypeItem.vue";
const modelService = require("@/utils/dataModel.js");

export default {
  components: { ProductType, ProductTypeItem },
  props: {
    // clinicData: {
    //   type: [Object],
    // },
  },
  data() {
    return {
      reqData: modelService.productTypeItemModel(),
      productTypeData: [],
    };
  },
  methods: {
    _infoUpdate() {
      this.getProductTypeData();
      // setTimeout(() => {
      //   this.$refs.refProductTypeItem._getData();
      // }, 300);
    },
    hospitalTypeChange(e) {
      this.reqData.hospitalType = e;
      this.getProductTypeData();
    },
    getProductTypeData() {
      const $h = this.$helper;
      this.productTypeData = [];
      this.reqData.endpoint = "productType";
      this.reqData.type_id = 0;
      this.reqData.isCommon = "Y";
      this.getDataQuery(this.reqData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            // items: this.makeItemName(item.productTypeItem), 
            productTypeNameKr: $h.definedStringI18($h.makeStringToArray(item.productTypeName, "@@"), 'kr'),
            itemNum: item.productTypeItem.length,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            value: item.type_id,
            text: $h.definedStringI18($h.makeStringToArray(item.productTypeName, "@@"), 'kr'), //item.productTypeName,
          }));
        }
        this.productTypeData = res;
      });
    },
    // makeItemName(productTypeItem) {
    //   let itemName = [];
    //   productTypeItem.forEach(element => {
    //     itemName.push(element.itemName);
    //   });
    //   return itemName;
    //   // return itemName.toString();
    //   // return this.makeOverText(itemName.toString());
    // },
    // makeOverText(string) {
    //   if(string === "") return;
    //   let dpTextNum = 30;
    //   if(string.length >= dpTextNum) {
    //     return string.substring(0, dpTextNum) + "..."
    //   }
    //   return string;
    // },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.vGap {
  height: 50px;
}
</style>