<template>
  <base-card>
    <v-card-text>
      <div class="d-flex justify-space-between align-center flex-wrap mb-4">
        <div class="d-flex align-center">
          <div>
            <p class="ma-0">
              <div class="typeCard" @click="$emit('clickType')">{{ title }}</div>
            </p>
            <p class="text--disabled caption ma-0">
              포함 아이템 수 : {{ subTitle }} 개
            </p>
            <p class="d-flex text--disabled caption ma-0">
              <div class="itemsDiv" v-for="(item, i) in items" :key="i"
                @click="$emit('clickItem', typeId, item)">
                <!-- {{ item.itemName }} -->
                {{ $helper.definedStringI18($helper.makeStringToArray(item.itemName, "@@"), 'kr') }}
              </div>
            </p>

            <div>
              <v-btn text class="ma-0" color="primary" @click="$emit('clickItem', typeId, null)">
                아이템 추가
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
export default {
  props: {
    avatar: {
      type: String,
      default: require("@/assets/images/avatars/004-bald.svg"),
    },
    title: {
      type: String,
      default: "Timothy Clarkson",
    },
    subTitle: {
      type: [String, Number],
      default: "Vue Developer",
    },
    items: {
      type: [Array],
    },
    typeId: {
      type: [Number],
    },
  },
  data() {
    return {
      // defaultFooterBtn: this.$t('patient.detail.photoCared.button.medicalInfo'),
    };
  },
  methods: {
  },
};
</script>
<style lang="scss">
.typeCard {
  font-size: 30px;
  font-weight: 700;
  &:hover {
    color: red;
    cursor: pointer;
  }
}
.itemsDiv:hover {
  color: red;
  cursor: pointer;
}
</style>