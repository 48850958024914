<template>
  <div>
    <v-row>
      <v-col md="12">
        <template>
          <v-row>
            <v-col
              v-for="(item, index) in data"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
            >
              <list-card
                :title="item.productTypeNameKr"
                :sub-title="item.itemNum"
                :items="item.productTypeItem"
                :typeId="item.value"
                :progress-linear-color="item.progressLinearColor"
                @clickType="$emit('clickType', item)"
                @clickItem="clickItem"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ListCard from "./Card.vue";
export default {
  components: { ListCard },
  props: {
    data: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    clickItem(typeId, item) {
      this.$emit('clickItem', typeId, item);
    }
  },
};
</script>

<style lang="scss">
.photoBox {
  text-align: center;
}
.iconStyle {
  font-size: 60px !important;
  color: rgb(210, 210, 210) !important;
}
.regTag {
  color: darkgray;
}
</style>